import { Component, HostListener, OnInit } from '@angular/core';
import { booleanToString, getParamValueQueryString, stringToBoolean } from '../../helpers/general.helper';
import { AuthService } from '../../state/auth/auth.service';

@Component({
    selector: 'app-dev-toolbar',
    templateUrl: './dev-toolbar.component.html',
    styleUrls: ['./dev-toolbar.component.scss']
})
export class DevToolbarComponent implements OnInit {
    selectedEnvironment: string;
    visible = false;
    production = false;

    environments = [
        'default',
        'mercury-current.dericon.it',
        'mercury.dericon.de',
        'mercury-ha.dericon.de',
        'sparkasse-bremen.mercury.dericon.de',
        'sparkasse-hannover.mercury.dericon.de',
        'sskduesseldorf.mercury.dericon.de',
        'spk-suedholstein.mercury.dericon.de',
        'sparkasse-bochum.mercury.dericon.de',
        'sparkasse-karlsruhe.mercury.dericon.de',
        'berliner-sparkasse.mercury.dericon.de',
        'ksk-verden.mercury.dericon.de',
        'blsk.mercury.dericon.de',
        'hanvb.mercury.dericon.de',
        'national-bank.mercury.dericon.de',
        'bethmannbank.mercury.dericon.de',
        'berenberg.mercury.dericon.de',
        'degussa-bank.mercury.dericon.de',
        'commerzbank.mercury.dericon.de',
        'fuggerbank.mercury.dericon.de',
        'verbundvolksbank-owl.mercury.dericon.de',
        'bisonwms.mercury.dericon.de',
        'lzo.mercury.dericon.de',
        'mercury.vv-marktplatz.de',
        'oddo-bhf.mercury.dericon.de'
    ];

    constructor(private authService: AuthService) {
        const fakeEnvironment = getParamValueQueryString('fakeEnvironment') || sessionStorage.getItem('fakeEnvironment');
        const productionEnvironment = getParamValueQueryString('productionEnvironment') || sessionStorage.getItem('productionEnvironment');

        this.production = productionEnvironment ? stringToBoolean(productionEnvironment) : false;
        this.selectedEnvironment = fakeEnvironment ? fakeEnvironment : 'default';
    }

    ngOnInit() {
    }

    onEnvironmentSelect(event: Event) {
        event.preventDefault();
        const selectedEnvironment: string = (event.target as any).value;
        window.location.href = '/?fakeEnvironment=' + selectedEnvironment + '&productionEnvironment=' + booleanToString(this.production);
    }


    @HostListener('document:keydown', ['$event'])
    public onKeyDown(event: KeyboardEvent) {
        if (event.ctrlKey && event.altKey && event.code === 'KeyO') {
            this.visible = !this.visible;
        }
    }

    onClickClose(event: MouseEvent) {
        event.preventDefault();

        this.visible = false;
    }

    onChangeProduction(value: boolean) {
        const val = booleanToString(value);

        window.location.href = '/?fakeEnvironment=' + this.selectedEnvironment + '&productionEnvironment=' + val;
    }

    onClickResetToken(event: MouseEvent) {
        event.preventDefault();

        this.authService.resetToken();
    }
}
